.wrapper {
    min-height: 100vh;
    background-color: black;
}

.safe-area {
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.registercontent {
    flex: 1;
    overflow: auto;
    background-color: black;
}

.registerheader {
    display: flex;
    align-items: center;
    padding-top: 20px;
    color: white;
}

.registerback-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-left: 5px;
    color: white;
}

.registernavigation-button-label {
    font-size: 18px;
}

.form-group {
    max-width: 500px;
    margin: auto;
}

.input-group {
    margin-top: 24px;
  text-align: left;
  /* border-bottom: 1px solid #dcdcdc; */
  padding-bottom: 7px;
}

label {
    display: block;
    color: grey;
    margin-bottom: 5px;
    text-align: left;
    font-size: 12px;
}

.label-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.labeltext {
    font-size: 16px;
    padding-bottom: 5px;
}

.nameInputContainer {
    display: flex;
    width: 100%; /* Set the width of the container to 100% */
    /* Rest of your styles... */
}

.registercontent .countryCodeDropdown{
    margin: 0 10px 10px 0;
    border-radius: 8px;
}

.nameInputStyle {
    border-radius: 8px;
    border: 0px solid var(--shadow-color);
    padding: 12px 16px;
    font-size: 14px;
    color: var(--black-color);
    width: 100%; /* Ensure padding and border are included in the width */
    border-bottom: 1px solid var(--shadow-color);
}

.input-group input {
    width: 100%;
    padding: 10px 0px;
    margin-bottom: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.divider {
    background-color: #ccc;
    height: 1px;
}

.registercontinue-button {
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 12px 24px; /* Adjust as needed */
    border-radius: 4px; /* Adjust as needed */
    font-size: 14px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    display: block;
    width: 80%; /* Adjust as needed */
    margin: 0px auto; /* Centers the button */
    text-align: center;
    margin-top: 20px
}

.registerbuttondiv {
     bottom: 0;
    position:absolute;
    width: 100%;
    margin-bottom: 30px;
    /* margin-inline: 35px; */
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.left-aligned-label {
    text-align: left;
}

