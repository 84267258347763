.home-screen {
  position: relative;
  height: 100vh;
  width: 100%;
}

.map-container {
  height: 100%;
}

.map-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.canavasHeader{ 
  padding: '0px'; 
  margin-top: '10px' 
}
.mapcurrent {
  color: rgb(255, 0, 0);
  margin-right: 5px;
}
.current-location-bar {
  background: white;
  padding: 10px;
  margin: 20px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  border-radius: 5px;
  height: 45px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.menu-bar {
 
  display: flex;
  align-items:start;
  
}

.hamburger-menu {
  background: white;
  padding: 11px;
  margin: 10px;
  font-size: 1rem;
  border-radius: 5px;
  height: 46px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  color: #000;
  width: 55px;
}

.user-image {
  width: 95px;
  height: 90px;
  border-radius: 50%;
  object-fit: contain;
  margin-inline: 21px;
  margin-top: 0px;
  margin-top: 0px;
  margin-right: 0px;
}

.maintextdiv {
  flex: 0.6 1 0%;
  align-items: center;
  margin-top: 45px;
}

.closeicondiv {
  display: flex;
  height: 80px;
  width: 80px;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
  position: absolute;
  right: -50px;
  top: 400px;
  background: rgb(255, 255, 255);
}

.closeicon {
  color: red;
  font-size: 32px;
  margin-left: 10px;
}
.nametext {
  color: white;
  font-weight: 600;
  font-size: 16px;
}
.emailtext {
  color: white;
  /* font-weight: 500; */
  font-size: 14px;
}

.offcanvas-header {
  margin-top: 0px !important;
}

.listingdiv {
  display: flex;
  align-items: center;
  padding: 20px 20px;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and text color */
}

.listingdiv:hover {
  background-color: #f0f0f0; 
  color: #333; 
}

/* You may also want to style the icons and text individually */
.listingdiv .circle-icon {
  margin-right: 10px; /* Ensures consistent spacing between the icon and the text */
  transition: transform 0.3s; /* Smooth scaling transition */
}

.listingdiv:hover .circle-icon {
  transform: scale(1.1); /* Slightly enlarge the icon on hover */
}

.listingdiv .headingtext {
  transition: color 0.3s; /* Smooth color transition */
}

.listingdiv:hover .headingtext {
  color: #ff4500; /* Changes text color on hover */
}

.circle-icon {
  background-color: red;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  align-items: center;
  padding: 6px;
  display: flex;
}
.circle-icon2 {
  background-color: red;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  align-items: center;
  padding: 6px;
  display: flex;
}
.circle-icon3 {
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  align-items: center;
  padding: 6px;
  display: flex;
  float: right;
  margin-bottom: -30px;
  margin-right: 5px;
  margin-top: 10px;
  z-index: 999999;
  position: relative;
}

.homecircle {
  color: rgb(255, 255, 255);
  font-size: 18px;
}
.ridecircle {
  color: rgb(255, 255, 255);
  font-size: 16px;
}
.editcircle {
  color: red;
  font-size: 17px;
}
.headingtext {
  margin-inline: 12px;
  font-size: 18px;
  /* font-weight: 500; */
}

.listingContainer {
  overflow: auto;
}

.noPermission {
  color: red;
  margin-bottom: 10px;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  flex-direction: column;
  padding: 0 2rem;
}

.cardheaderstyle {
  background-color: red;
  border-top-right-radius: 10px;
}

.homecarddiv {
  /* border-radius: 16px; */
  background-color: red;
  padding: 8px;
  height: 150px;
}

.homebodydiv {
  padding-top: 60px;
  text-align: left;
  color: white;
}

/* Define slide-up animation */
@keyframes slide-up {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
.recent-destinations-container {
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  animation: slide-up 0.5s ease-out;

}

@media (min-width: 1024px) {
  .recent-destinations-container {
    width: 600px; 
    margin-left: auto;
    margin-right: auto;
  }
}

/* Apply animation to the sheet content */
.recent-destinations-sheet {
  animation: slide-up 0.5s ease-out;
}

.recent-destinations-bar {
  width: 50px;
  height: 5px;
  background-color: #ff0000;
  margin: 10px auto 20px auto;
  border-radius: 2.5px;
  cursor: pointer;
}

.destination-search-button {
  width: 100%;
  text-align: left;
  height: 50px;
  padding: 10px;
  font-size: 1rem;
  background-color: #e6e6e6;
  border-radius: 5px;
  border: none;
  color: #000000;
}

.recent-destinations {
  list-style: none;
  padding: 0;
  margin: 0;
}

.destination-item:hover {
  cursor: pointer;
  background-color: #f0f0f0; /* Light gray background color */
  transition: background-color 0.3s ease; /* Add a smooth transition effect */
}

.destination-item {
  display: flex;
  text-align: left;
  padding: 25px 10px;
  margin: 5px 0px;
  border-bottom: 1px solid #dcdcdc;

}
.destination-item2 {
  display: flex;
  text-align: left;
  padding: 20px 10px;
  border-bottom: 0.3px solid #dcdcdc;
}

.destination-item2:hover {
  cursor: pointer;
  background-color: #f0f0f0; /* Light gray background color */
  transition: background-color 0.3s ease; /* Add a smooth transition effect */
}

.hrline {
  border-bottom: 0.3px solid #e6e6e6;
  margin-left: 47px;

}

.destination-item-favorite {
  margin-right: 10px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6e6e6;
  border-radius: 50%;
}

.destination-icon {
  margin-right: 10px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.destination-item-favorite svg {
  margin: 5px;
}


.destination-name {
    font-weight: 600;
    font-size: 15px;
}

.destination-address {
  font-size: 0.9rem;
  color: #666;
}

/* Include FontAwesome CDN in your index.html for the crosshairs icon */
.fas.fa-crosshairs {
  margin-right: 10px;
}

.current-location-text {
  margin-left: 16px;
  color: gray;
}


.home-screen-container {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  margin-left: 10px;
}

.homeheader {
  display: flex;
  align-items: center;
}

.back-button {
  font-size: 30px;
  cursor: pointer;
}
.firsticon {
  font-size: 29px;
  margin-right: 10px;
}

.secondicon {
  font-size: 21px;
  margin-right: 10px;
}

.source-destination-section {
  display: flex;
}

.connection-line {
  display: flex;
  flex-direction: column; /* Change to column layout */
  align-items: center;
}

.dotted-line {
  width: 2px;
  height: 19px;
  border-left: 2px dotted #000000;
  margin: 8px 0px;
  padding: 5px;
}

.icondiv {
  /* display: flex; */
  margin-left: 10px;
}

.progress-step__marker {
  width: 1px; 
  background-color: black; 
}

.recenttext{
  font-size: 17px;
  font-weight: 500;
  margin-left: 15px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #ff0000;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.location-autocomplete { 
  padding-left: 35px;
  width: 100%;
  height: 50px;
  background-color: rgb(230, 230, 230);
  border-radius: 6px;
  border-color: rgb(230, 230, 230);
  border-style: none;
 }

 .location-autocomplete.error {
  border-color: #FB2200 !important; /* Use important carefully */
  border-style: solid !important;
}

.offcanvas-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end; /* Ensures the footer is at the bottom */
  padding: 0; /* Ensure there's no extra padding around the body */
}

/* Container for the footer links */
.footer-links {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

/* Style for each footer link */
.footer-link {
  margin: 0 10px; /* Adds space between the links */
  text-decoration: none;
  color: #666;
  font-size: 14px;
}

.footer-link:hover {
  color: #007bff;
}
