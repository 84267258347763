.wrapper {
    height: 100vh;
    background-color: black;
}

.verification-container {
    background-color: black;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    color: #fff;
}

.header {
    display: flex;
    align-items: center;
    padding-top: 20px;
    color: white;
}

.verifynavigation-button-label {
    font-size: 18px;
}

.new-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 500px;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.input-wrapper {
    display: flex
}

.otp-input {
    margin: 10px;
    padding: 10px;
    font-size: 16px;
    border-bottom: 1px solid #ccc;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    width: 50%;
    text-align: center;
    background-color: #000;
    color: #ffff;

}

.continue-button {
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 12px 24px; /* Adjust as needed */
    border-radius: 4px; /* Adjust as needed */
    font-size: 14px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    display: block;
    width: 100%; /* Adjust as needed */
    max-width: 250px;
    margin: 0px auto; /* Centers the button */
    text-align: center;
    margin-top: 20px;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #ff0000;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.resend {
    /* margin-top: 20px; */
    color:gray;
    font-size: 14px;
}

.resend-link {
    color: var(--primary-color);
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
}

.arrow-left {
    cursor: pointer;
    margin-right: 10px;
}

.back-button {
    background: none;
    border: none;
    font-size: 26px;
    cursor: pointer;
    margin-left: 5px;
    color: white;
}

.successMessageStyle {
    color: #0d5c20;
    background-color: #61ef82;
    border-color: #4bc166;
    padding: 0.75rem 1.25rem; 
    margin-bottom: 1rem; 
    border: 1px solid transparent; 
    border-radius: 0.25rem; 
    text-align: center; 
    width: 100%;
}

.text2 {
    color:gray;
    font-size: 12px;
}
.verifbuttonStyle {
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 12px 24px; /* Adjust as needed */
    border-radius: 4px; /* Adjust as needed */
    font-size: 14px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    display: block;
    width: 100%; /* Adjust as needed */
    max-width: 250px;
    margin: 0px auto; /* Centers the button */
    text-align: center;
    margin-top: 20px
  }

.verifbuttondiv {
    bottom: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-bottom: 30px;
  /* left: 50px; */
}