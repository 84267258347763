.methodheader {
    display: flex;
    align-items: center;
    padding-top: 20px;
    margin-left: 10px;
}
.methodtext {
  font-size: 23px;
  font-weight: 600;
}
.headerback-button {
    background: none;
    border: none;
    font-size: 26px;
    cursor: pointer;
}

.plusicon {
  font-size: 19px;
  font-weight: '500';
  margin-right: 8px;
}

.paymentbuttondiv {
  bottom: 0;
  position:absolute;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-bottom: 30px;
/* left: 50px; */
}

.paymentbuttonStyle {
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 12px 24px; /* Adjust as needed */
  border-radius: 4px; /* Adjust as needed */
  font-size: 17px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  display: block;
  width: 60%; /* Adjust as needed */
  margin: 0px auto; /* Centers the button */
  text-align: center;
  margin-top: 20px
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #ff0000;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.empty-text {
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  margin-top: 20px;
  font-size: 20px;
  color: #979797; 
}


