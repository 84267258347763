.carddiv {
  border-radius: 16px;
  background-color: red;
  padding: 8px;
  height: 150px;
}

.textcard {
  margin-top: 24px;
  text-align: left;
  /* border-bottom: 1px solid #dcdcdc; */
  padding-bottom: 7px;
}

.textlabel {
  color: gray;
  font-weight: 600;
  padding-bottom: 5px;
}

.textvalue {
  font-size: 13px;
  color: gray;
  margin-left: 5px;
}

.bodydiv {
  padding-top: 60px;
  text-align: left;
  color: white;
}

.fulltext {
  display: flex;
}
.bookmap-container {
  height: 100%;
}

.bookmap-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.booking-destination-name {
  font-weight: bold;
  margin-top: 5px;
}
.booking-destination-item {
  display: flex;
  text-align: left;
  padding: 16px 10px;
  border-bottom: 1px solid #dcdcdc;
}

.booking-destination-icon {
  margin-right: 10px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paymentheader {
  display: flex;
  align-items: center;
  padding-top: 20px;
  margin-left: 10px;
}
.paymenttext {
  font-size: 23px;
  font-weight: 600;
}
.headerback-button {
  background: none;
  border: none;
  font-size: 26px;
  cursor: pointer;
  color: #000;
}

.plusicon {
  font-size: 19px;
  font-weight: "500";
  margin-right: 8px;
}

.cardnumberInputContainer {
  width: 100%; /* Set the width of the container to 100% */
  /* Rest of your styles... */
}

.cardnumberInputStyle {
  border-radius: 8px;
  border: 0px solid var(--shadow-color);
  padding: 12px 16px;
  font-size: 14px;
  color: var(--black-color);
  width: 100%; /* Ensure padding and border are included in the width */
  border-bottom: 1px solid var(--shadow-color);
}

.addbuttondiv {
  bottom: 0;
  position: absolute;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-bottom: 30px;
  /* left: 50px; */
}

.addbuttonStyle {
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 12px 24px; /* Adjust as needed */
  border-radius: 4px; /* Adjust as needed */
  font-size: 17px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  display: block;
  width: 100%; /* Adjust as needed */
  margin: 0px auto; /* Centers the button */
  text-align: center;
  margin-top: 20px;
}

.cardValidationStyle {
  color: #fff;
  background-color: #e91607;
  border-color: #e91607;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  text-align: center;
  width: 100%;
}

.successMessageStyle {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  text-align: center;
  width: 100%;
}

.successButton {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  margin-left: 10px;

  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
