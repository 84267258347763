.driverheader {
  display: flex;
  align-items: center;
  padding-top: 20px;
  margin-left: 10px;
}
.driverback-button {
  background: none;
  border: none;
  font-size: 25px;
  cursor: pointer;
  font-weight: 500;
}
.driverback-button1 {
  background: none;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.driver-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: contain;
  margin-bottom: 10px;
  margin-inline: 21px;
}

.hrline1 {
  border-bottom: 0.3px solid #e6e6e6;
  margin-left: 47px;

}

.driverdotted-line {
  width: 2px;
  height: 19px;
  border-left: 2px dotted #000000;
  margin: 8px 0px;
  padding: 5px;
}

.driverbooking-destination-item {
  display: flex;
  text-align: left;
  padding: 9px 10px;
  align-items: center;
  /* border-bottom: 1px solid #dcdcdc; */
}

.driverrecent-destinations {
  list-style: none;
  padding: 0;
  margin: 0;
}
.driverdestination-name {
  font-weight: 500;
  font-size: 14px;
}

.call-icon {
color: red;
font-size: 20px;
}

.text-icon {
  color: red;
  font-size: 20px;
}

.moreBtn {
  color: black; /* Set font color to black */
  background-color: transparent; /* Set background to transparent */
  border: none; /* Remove border */
  cursor: pointer; /* Add pointer cursor for interaction */
}

.driver-name {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.vehicle-description-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  border-bottom: 1px solid #ece6e6;
}

.vehicle-description {
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.vehicle-description-item {
  text-align: center;
  margin-right: 10px;
}

.vehicle-description-item-subtitle {
  font-family: "Nunito Sans", sans-serif;
  font-size: 13.5px;
  color: var(--gray-color);
  text-align: left;
  width: 100%;
  margin-bottom: 0px;
}

.vehicle-description-item-details {
  font-weight: 600;
}

.moreBtn {
  color: black; /* Set font color to black */
  background-color: transparent; /* Set background to transparent */
  border: none; /* Remove border */
  cursor: pointer; /* Add pointer cursor for interaction */
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  display: block;
  width: 100%;
  margin: 0px auto;
  text-align: center;
  margin-top: 20px;
}

.moreicon {
  font-weight: bold;
}
.paymentcard {
  text-align:left; 
  font-weight:600; 
  font-size:16px;
  margin-inline: 10px;
}
.othercard {
  text-align:left; 
  font-weight:600; 
  font-size:16px;
  margin-top:20px;
  margin-inline: 10px;
}
.buttonStyle {
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 12px 24px; /* Adjust as needed */
  border-radius: 4px; /* Adjust as needed */
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;

  width: 100%; /* Adjust as needed */

 
 
}

.infocard{
  display: flex;
}

.infodiv {
  flex: 0.3;
  color: gray;
  font-size: 13px;
}
.infovalue {
  color: black;
  font-size: 14px;
  font-weight: 500;
}
.triphrline {
  border-bottom: 0.3px solid #e6e6e6;

}