:root {
    /* Colors */
    --primary-color: #FB0101;
    --black-color: #000000;
    --white-color: #FFFFFF;
    --gray-color: #949494;
    --light-gray-color: #B7B7B7;
    --shadow-color: #E6E6E6;
    --light-black-color: #3F3D56;
    --orange-color: #FFAC33;
    --red-color: #FF0000;

    /* Sizes */
    --fix-padding: 10px;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    border:1
}

.topContainer{
    display: flex;
    flex-direction: row;
  }

.navigation-button-label {
    font-size: 18px;
}
/* Title and Subtitle container */
.textContainer {
    padding-left: var(--fix-padding); /* Use the padding variable from your styles */
}

/* Title styling */
.title {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 20px; /* Adjust as needed */
    font-weight: bold;
    color: var(--white-color);
    text-align: left;
    margin-bottom: 8px; /* Adjust as needed */
}

.welcomeInfoContainer {
    display: flex;
    justify-content: flex-start;    
    align-items: center;
    width: 100%;
    /* other styles if necessary... */
}

/* Subtitle styling */
.subtitle {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    color: var(--gray-color);
    text-align: left; /* Left-align the text */
    width: 100%; /* The text takes the full width of the container */
    margin-bottom: 10px; /* Adjust as needed */

}


.white-color-14-regular {
    color: var(--white-color);
    font-size: 14px;
    font-family: 'Nunito Sans', sans-serif; /* Example - Adjust based on actual import */
    font-weight: 400; /* Regular */
}


/* Use this class for the SafeAreaView equivalent */
.safeArea {
    background-color: var(--black-color);
    padding-top: 20px; /* Adjust as needed for status bar space */
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.phoneInputContainer {
    width: 100%; /* Set the width of the container to 100% */
    /* Rest of your styles... */
}

/* Phone input styling */
.phoneInputStyle {
    border-radius: 8px;
    border: 1px solid var(--shadow-color);
    padding: 12px 16px;
    font-size: 14px;
    color: var(--black-color);
    width: 100%;
    box-sizing: border-box; /* Ensure padding and border are included in the width */

}

.exitInfoWrapStyle {
    background-color: #333; /* Replace with actual color */
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 20px;
    padding: 15px;
    text-align: center;
}

/* Continue button styling */
.buttonStyle {
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 12px 24px; /* Adjust as needed */
    border-radius: 4px; /* Adjust as needed */
    font-size: 14px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    display: block;
    width: 80%; /* Adjust as needed */
    margin: 0px auto; /* Centers the button */
    text-align: center;
    margin-top: 20px
}


/* Continue button styling */
.buttonStyleFw {
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 12px 24px; /* Adjust as needed */
    border-radius: 4px; /* Adjust as needed */
    font-size: 14px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    display: block;
    width: 100%; /* Adjust as needed */
    margin: 0px auto; /* Centers the button */
    text-align: center;
    margin-top: 20px
}



/* Adjust the image to fit as shown in the design */
.loginImage {
    width: 100%;
    height: auto; /* Adjust height to maintain aspect ratio */
    object-fit: cover;
}

.centeredContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: auto; /* Automatically adjust left margin for centering */
    margin-right: auto; /* Automatically adjust right margin for centering */
    max-width: 320px; /* Set a maximum width for the container, adjust as needed */
    /* Rest of your styles... */
}
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(227, 223, 223, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #ff0000;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

.loader-container {
  width: 100%;
  height: 4px;
  position: relative;
  overflow: hidden;
}

.loader-container .loader {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: red;
  animation: loaderAnimation 2s infinite;
}

@keyframes loaderAnimation {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

