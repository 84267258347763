.faqheader {
  display: flex;
  align-items: center;
  padding-top: 20px;
  margin-left: 6px;
}

.faqback-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-left: 3px;
}

.faqtext {
  font-size: 23px;
  font-weight: 600;
}

.faqcards {
  max-width: 500px;
  margin: auto;
  padding: 20px;
}

.signintext {
  text-align: left;
  margin-inline: 20px;
  font-size: 14px;
  color: gray;
  border-bottom: 1px solid rgb(209, 209, 209);
  padding-bottom: 10px;
  margin: 20px 0 12px;
}
.cabtext {
  text-align: left;
  margin-inline: 20px;
  font-size: 14px;
  color: gray;
}

.accordion {
  --bs-accordion-btn-icon-width:12px;
  --bs-accordion-border-width:0px
}
.accordion-body {
  padding: 3px;
  margin-left: 16px;
  text-align: left;
  font-size: 14px;
  color: gray;
}
.accordion-button {
  padding: 4px;
  padding-inline: 20px;
  font-size: 14px;
  font-weight: 500;
}
.accordion-button::after {
  background-color: white;
  margin-bottom: 13px;

}


.accordion .accordion-button::after{
  background-image: url(../../../public/assets/images/chevron-up.png);
  transform: rotate(-180deg);
}

.accordion-button.collapsed::after{
  background-image: url(../../../public/assets/images/chevron-down.png);
}

.accordion-button:not(.collapsed) {
  background-color: white;
  color:black;
  font-size: 14px;

}
.accordion-button:focus {
  border-color: white;
  box-shadow: none;
}
.contactcontinue-button {
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 12px 24px; /* Adjust as needed */
  border-radius: 4px; /* Adjust as needed */
  font-size: 14px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  display: block;
  width: 100%; /* Adjust as needed */
  margin: 0px auto; /* Centers the button */
  text-align: center;
  margin-top: 20px
}

.contactbuttondiv {
  bottom: 0;
  position:absolute;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-bottom: 30px;
/* left: 50px; */
}



