.editheader {
  display: flex;
  align-items: center;
  padding-top: 20px;
  margin-left: 10px;
}

.editback-button {
background: none;
border: none;
font-size: 24px;
cursor: pointer;
margin-left: 5px;
}

.edittext {
font-size: 23px;
font-weight: 600;
}

.edituser-image {
  width: 95px;
  height: 90px;
  border-radius: 50%;
  object-fit: contain;
  margin-inline: 21px;
  margin-top: 0px;
  margin-top: 0px;
  margin-right: 0px;
}
.editlabel-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.editlabeltext {
  font-size: 16px;
}
.editnameInputContainer {
  width: 100%; /* Set the width of the container to 100% */
  /* Rest of your styles... */
}

.editnameInputStyle {
  border-radius: 8px;
  border: 0px solid var(--shadow-color);
  padding: 5px ;
  font-size: 14px;
  color: var(--black-color);
  width: 100%; /* Ensure padding and border are included in the width */
  border-bottom: 1px solid var(--shadow-color);
}
.editnameInputStyle::placeholder {
  color: black;
  font-weight: 500;
  opacity: 1; 
}
/* input::placeholder {
  color: black;
} */

.sheetcontainer{
  z-index: 2;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px -2px 16px;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  /* height: calc(100% - env(safe-area-inset-top) - 34px); */
  transform: none;
}

.cameracircle-icon {
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  float: right;
  margin-left: -151px;
  margin-right: 135px;
  margin-top: 65px;
  z-index: 999999;
  position: relative;
  padding: 5px;
}
.cameracircle {
  color: red;
  font-size: 17px;
}

.choose-destinations-container {
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  animation: slide-up 0.5s ease-out;

}
.choose-destinations-bar {
  width: 50px;
  height: 5px;
  background-color: #ff0000;
  margin: 10px auto 20px auto;
  border-radius: 2.5px;
}
.choose-destinations {
  list-style: none;
  padding: 0;
  margin: 0;
}
.chooseOption-sheet {
  animation: slide-up 0.5s ease-out;
}
.chooseOption-container {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

}
.cameraIcon {
  color: #b7b1b1;
  font-size: 18px;
}
.deleteIcon {
  color: #b7b1b1;
  font-size: 24px;
}
.cameratext {
  font-size: 17px;
  color: #b7b1b1;
  margin-left: 16px;
}
.optionheader {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.editcontinue-button {
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 12px 24px; /* Adjust as needed */
  border-radius: 4px; /* Adjust as needed */
  font-size: 14px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  display: block;
  width: 80%; /* Adjust as needed */
  margin: 0px auto; /* Centers the button */
  text-align: center;
  margin-top: 20px
}

.editbuttondiv {
  bottom: 0;
  position:absolute;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-bottom: 30px;
/* left: 50px; */
}

.pointer {
  cursor: pointer;
}

.pointer:active {
  opacity: 0.5;
}