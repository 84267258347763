.editheader {
  display: flex;
  align-items: center;
  padding-top: 20px;
  margin-left: 10px;
}

.editback-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-left: 5px;
}

.myridecarddiv {
  border-radius: 16px;
  /* background-color: red; */
  /* height: 150px; */
  max-width: 500px;
  margin: auto;
  margin-bottom: 2%;
}
.myridebodydiv {
  text-align: left;
  width: 100%;
}
.rideuser-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.carName {
  font-size: 14px;
  color: gray;
}
.pricestyle {
  color: red;
  font-weight: 500;
  text-align: right;
}

.myridesource-destination-section {
  display: flex;
}
.myrideconnection-line {
  display: flex;
  flex-direction: column; /* Change to column layout */
  align-items: center;
}

.myridefirsticon {
  font-size: 23px;
  margin-right: 10px;
}

.myridesecondicon {
  font-size: 16px;
  margin-right: 10px;
}

.myridehrline {
  border-bottom: 0.3px solid #e6e6e6;
}
.myridedotted-line {
  /* width: 2px; */
  height: 26px;
  border-left: 2px dotted #000000;
  margin: 4px;
  padding: 5px;
}

.myrideicondiv {
  font-size: 14px;
}
.myridedestination-location-text {
  font-size: 14px;
  padding-top: 4px;
}

.myrideprogress-step__marker {
  width: 1px;
  background-color: black;
}


.empty-text {
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  margin-top: 20px;
  font-size: 20px;
  color: #979797; 
}