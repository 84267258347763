:root {
    /* Colors */
    --primary-color: #FB0101;
    --black-color: #000000;
    --white-color: #FFFFFF;
    --gray-color: #949494;
    --light-gray-color: #B7B7B7;
    --shadow-color: #E6E6E6;
    --light-black-color: #3F3D56;
    --orange-color: #FFAC33;
    --red-color: #FF0000;

    /* Sizes */
    --fix-padding: 10px;
}

.logincontent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 100%; */
    /* flex:1, */
}

.login-image {
    max-width: 400px;
    margin: 20px auto;
    /* width: 100%; */
    width: auto;
}

.loader-btn {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #ff0000;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

.inline-loader {
    display: flex; /* Enables flexbox layout */
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
    height: 100%; /* Sets the height to fill the container (adjust as needed) */
  }

/* 
@media (min-width: 600px) {
    .login-image {
        max-width: 400px; Larger max-width for wider screens
    }
}

@media (min-width: 768px) {
    .login-image {
        max-width: 500px; Even larger max-width for wider screens
    }
} */


.navigation-button-label {
    font-size: 18px;
}
/* Title and Subtitle container */
.textContainer {
    padding-left: var(--fix-padding); /* Use the padding variable from your styles */
}

/* Title styling */
.title {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 20px; /* Adjust as needed */
    font-weight: bold;
    color: var(--white-color);
    text-align: left;
    margin-bottom: 8px; /* Adjust as needed */
}

.welcomeInfoContainer {
    display: flex;
    justify-content: flex-start;    
    align-items: center;
    width: 100%;
    /* other styles if necessary... */
}

/* Subtitle styling */
.subtitle {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    color: var(--gray-color);
    text-align: left; /* Left-align the text */
    width: 100%; /* The text takes the full width of the container */
    margin-bottom: 10px; /* Adjust as needed */

}


.white-color-14-regular {
    color: var(--white-color);
    font-size: 14px;
    font-family: 'Nunito Sans', sans-serif; /* Example - Adjust based on actual import */
    font-weight: 400; /* Regular */
}


/* Use this class for the SafeAreaView equivalent */
.safeArea {
    background-color: var(--black-color);
    padding-top: 20px; /* Adjust as needed for status bar space */
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.alertMessageStyle {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 0.75rem 1.25rem; 
    margin-bottom: 1rem; 
    border: 1px solid transparent; 
    border-radius: 0.25rem; 
    text-align: center; 
    width: 100%;
}

.phoneInputContainer {
    display: flex;
    width: 100%; /* Set the width of the container to 100% */
    /* Rest of your styles... */
}

.countryCodeDropdown{
    margin-right: 10px;
    border-radius: 8px;
}
/* Phone input styling */
.phoneInputStyle {
    border-radius: 8px;
    border: 1px solid var(--shadow-color);
    padding: 12px 16px;
    font-size: 14px;
    color: var(--black-color);
    width: 100%;
    box-sizing: border-box; /* Ensure padding and border are included in the width */

}

.exitInfoWrapStyle {
    background-color: #333; /* Replace with actual color */
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 20px;
    padding: 15px;
    text-align: center;
}

/* Continue button styling */
.loginbuttonStyle {
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 12px 24px; /* Adjust as needed */
    border-radius: 4px; /* Adjust as needed */
    font-size: 14px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    display: block;
    width: 100%; /* Adjust as needed */
    height: 55px;
    margin: 0px auto; /* Centers the button */
    text-align: center;
    margin-top: 20px;
    box-sizing: border-box;
}

.loginbuttondiv {
    bottom: 0;
    position:absolute;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-bottom: 30px;
  /* left: 50px; */
}

/* Adjust the image to fit as shown in the design */
.loginImage {
    width: 100%;
    height: auto; /* Adjust height to maintain aspect ratio */
    object-fit: cover;
}

.centeredContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: auto; /* Automatically adjust left margin for centering */
    margin-right: auto; /* Automatically adjust right margin for centering */
    max-width: 320px; /* Set a maximum width for the container, adjust as needed */
    /* Rest of your styles... */
}
