.onboarding-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full viewport height */
    background-color: #000;
    color: #fff;
    overflow: hidden;
}

/* Make sure SwipeableViews occupies the full height of the container */
.swipeable-views-container {
    display: flex;
    flex-direction: column;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

.slide {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically within each slide */
    align-items: center; /* Center content horizontally */
    height: 100%; /* Full height of the container */
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}


.onboarding-image {
    max-width: 400px; /* Maximum width for the image */
    height: auto; /* Height will scale automatically */
    margin: 20px auto;
}

@media (min-width: 600px) {
    .onboarding-image {
        max-width: 400px; /* Larger max-width for wider screens */
    }
}

@media (min-width: 768px) {
    .onboarding-image {
        max-width: 500px; /* Even larger max-width for wider screens */
    }
}
.onboarding-title {
    font-size: 24px;
    margin-bottom: 10px;
}

.onboarding-description {
    font-size: 16px;
    margin: 0 auto 20px;
    max-width: 80%;
    text-align: center;
}

.onboarding-controls {
    display: flex;
    justify-content: space-between; /* Adjust the alignment */
    align-items: center;
    padding: 20px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    width: calc(100% - 40px); /* Adjust width */
}

.skip-button {
    background: none;
    border: none;
    color: #ff0000; /* Set the color as needed */
    cursor: pointer;
    font-size: 1.2rem;
    transition: color 0.3s, text-decoration 0.3s; /* Smooth transition for hover effect */

}

.skip-button:hover {
    color: #ffffff; /* Change color on hover */
    text-decoration: underline; /* Add underline on hover */
}

.control-button {
    width: 55px; /* Width of the button */
    height: 55px; /* Height of the button - should be the same as width for a circle */
    border-radius: 50%; /* This will make it a perfect circle */
    border: none;
    background-color: #fff;
    color: #000;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Optional: adds a slight shadow for depth */
}

.control-button.next-button,
.control-button.back-button {
    padding: 0;
    font-size: 24px; /* Adjust the font size as needed */
    line-height: 1; /* This helps with vertical centering */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effect */

}

.control-button.next-button:hover,
.control-button.back-button:hover,
.control-button.done-button:hover {
    background-color: #e0e0e0; /* Change background color on hover */
    transform: scale(1.1); /* Slightly increase size on hover */
}

.control-button.done-button {
    width: 100px;
}


.onboarding-indicators {
    position: absolute;
    bottom: 20px; /* Place above the controls */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
}

.indicator {
    height: 10px;
    width: 10px;
    background-color: #ffffff;
    border-style: solid 2px #ff0000;
    margin: 0 5px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s, width 0.3s, border-radius 0.3s; /* Animate these properties */

}

.indicator.active {
    background-color: #ff0000;
    border-radius: 5px;
    width: 30px;
}
