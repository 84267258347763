.contactheader {
  display: flex;
  align-items: center;
  padding-top: 20px;
  margin-left: 6px;
}

.contactback-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-left: 5px;
}

.contacttext {
  font-size: 23px;
  font-weight: 600;
}

.contactcontainer {
  max-width: 500px;
  margin: auto;
}

.issuetext {
  text-align: left;
  margin-inline: 25px;
  font-size: 18px;
  font-weight: 600;
}
.sendtext {
  text-align: left;
  margin-inline: 20px;
  font-size: 18px;
  font-weight: 600;
}
.icontextdiv {
  display: flex;
  margin: 24px;
  align-items: center;
  margin-bottom: 0px;
}
.mailicondiv {
  display: flex;
  margin-inline: 27px;
  align-items: center;
  margin-bottom: 0px;
  margin-top: 15px;
}
.callcircle {
  color: red;
  font-size: 21px;
}
.numbertext {
  margin-inline: 12px;
  font-size: 18px;
  color: gray;
  text-decoration: none;
}

.contactlabel-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.contactlabeltext {
  font-size: 16px;
}
.contactnameInputContainer {
  width: 100%; /* Set the width of the container to 100% */
  /* Rest of your styles... */
}

.contactnameInputStyle {
  border-radius: 8px;
  border: 0px solid var(--shadow-color);
  font-size: 17px;
  font-weight: 500;
  color: var(--black-color);
  width: 100%; /* Ensure padding and border are included in the width */
  border-bottom: 1px solid var(--shadow-color);
  text-align: left;
}
.textareaInputStyle {
  border-radius: 8px;
  border: 0px solid var(--shadow-color);
  font-size: 17px;
  width: 100%; /* Ensure padding and border are included in the width */
  border-bottom: 1px solid var(--shadow-color);
  text-align: left;
  color: gray;
}


.contactnameInputContainer {
  width: 100%; /* Set the width of the container to 100% */
  /* Rest of your styles... */
}

.contactcontinue {
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 12px 24px; /* Adjust as needed */
  border-radius: 4px; /* Adjust as needed */
  font-size: 14px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  display: block;
  width: 80%; /* Adjust as needed */
  margin: 0px auto; /* Centers the button */
  text-align: center;
  margin-top: 20px
}

.contactbuttondiv {
  bottom: 0;
  position:absolute;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-bottom: 30px;
}

.contactbuttondiv div {
  max-width: 350px;
  margin: auto;
}
