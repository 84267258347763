.tripback-button {
    background: none;
    border: none;
    font-size: 28px;
    cursor: pointer;
}
.tripheader {
    text-align: left;
    margin-left: 10px;
}

.endcurrent-location-bar {
    background: white;
    padding: 10px;
    margin: 20px;
    display: flex;
    align-items: center;
    font-size: 1rem;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
.endcurrent-location-text {
    margin-left: 14px;
    color: gray;
  }
.mintext {
    color: black;
    text-align: left;
  }

  .driverheader {
    display: flex;
    align-items: center;
    padding-top: 20px;
    margin-left: 10px;
  }
  .enddriver-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: contain;
    margin-bottom: 10px;
    margin-inline: 21px;
  }
  
  .tripdriver-name {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .trip-description-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }
  
  .trip-description {
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .trip-description-item {
    text-align: center;
    margin-right: 10px;
  }
  
  .trip-description-item-subtitle {
    font-family: "Nunito Sans", sans-serif;
    font-size: 13.5px;
    color: var(--gray-color);
    text-align: left;
    width: 100%;
    margin-bottom: 0px;
  }
  
  .trip-description-item-details {
    font-weight: 600;
  }
  
  .triptext {
    text-align: left;
  font-size: 15px;
  font-weight: 500;
  }
  
  .tripsource-destination-section {
    display: flex;
  }
  .tripconnection-line {
    display: flex;
    flex-direction: column; /* Change to column layout */
    align-items: center;
  }

  .tripfirsticon {
    font-size: 29px;
    margin-right: 10px;
  }
  
  .tripsecondicon {
    font-size: 21px;
    margin-right: 10px;
  }
  
  .triphrline {
    border-bottom: 0.3px solid #e6e6e6;
  
  }
  .tripdotted-line {
    width: 2px;
    height: 19px;
    border-left: 2px dotted #000000;
    margin: 8px 0px;
    padding: 5px;
  }
  
  .tripicondiv {
    /* display: flex; */
    margin-left: 10px;
  }
  
  .tripprogress-step__marker {
    width: 1px; 
    background-color: black; 
  }
  