.notiheader {
    display: flex;
    align-items: center;
    padding-top: 20px;
    margin-left: 10px;
}

.notilisting {
  display: flex;
  margin-inline: 22px;
  max-width: 500px;
  margin: auto;
}
.noticircle-icon {
  background-color: #e4e1e1;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  align-items: center;
  padding: 6px;
  display: flex;
  justify-content: center;
}
.iconcircle {
  color: rgb(70, 70, 70);
  font-size: 23px;
}
.notiheadingtext {
  text-align: left;
  margin-left: 12px;
  font-size: 18px;
  font-weight: 500;
}
.notisecondtext {
  color: #b9b0b0;
font-size: 13.5px;
}

.notitimetext {
  color: #b9b0b0;
font-size: 14px;
}

.empty-text {
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  margin-top: 20px;
  font-size: 20px;
  color: #979797; 
}
