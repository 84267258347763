.bookmap-container {
    height: 100%;
  }
  
.bookmap-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .payment-destination-name {
    font-weight: bold;
    margin-top: 5px;
  }
  .payment-destination-item {
    display: flex;
    text-align: left;
    padding: 16px 10px;
    border-bottom: 1px solid #dcdcdc;
  }

.payment-destination-icon {
    margin-right: 10px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#dropdown-basic {
  width: 100%;
  border: 1px solid #676767;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  color: rgb(41, 41, 41);
}



.bookheader {
    display: flex;
    align-items: center;
    padding-top: 20px;
    margin-left: 10px;
}
.booktext {
    font-size: 23px;
}
.headerback-button {
    background: none;
    border: none;
    font-size: 26px;
    cursor: pointer;
    color: #000;
}

.paymentfillicon {
  font-size: 29px;
  margin-right: 10px;
}

.paymentlocationicon {
  font-size: 21px;
  margin-right: 10px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #ff0000;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
