.endback-button {
    background: none;
    border: none;
    font-size: 28px;
    cursor: pointer;
}
.endheader {
    text-align: left;
    margin-left: 10px;
    position: absolute;
}

.endcurrent-location-bar {
    background: white;
    padding: 10px;
    margin: 20px;
    display: flex;
    align-items: center;
    font-size: 1rem;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
.endcurrent-location-text {
    margin-left: 14px;
    color: gray;
  }
.mintext {
    color: black;
    text-align: left;
  }

  .driverheader {
    display: flex;
    align-items: center;
    padding-top: 20px;
    margin-left: 10px;
  }
  .newuser-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: contain;
    margin-bottom: 10px;
    margin-inline: 21px;
  }
  
  .endcall-icon {
  color: red;
  font-size: 20px;
  }
  
  .endtext-icon {
    color: red;
    font-size: 20px;
  }
  
  .enddriver-name {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .end-description-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }
  
  .end-description {
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .end-description-item {
    text-align: center;
    margin-right: 10px;
  }
  
  .end-description-item-subtitle {
    font-family: "Nunito Sans", sans-serif;
    font-size: 13.5px;
    color: var(--gray-color);
    text-align: left;
    width: 100%;
    margin-bottom: 0px;
  }
  
  .end-description-item-details {
    font-weight: 600;
  }
  
  .endbuttonStyleFw {
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 12px 24px; /* Adjust as needed */
    border-radius: 4px; /* Adjust as needed */
    font-size: 14px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    display: block;
    width: 100%; /* Adjust as needed */
    margin: 0px auto; /* Centers the button */
    text-align: center;
    margin-top: 20px
}  